import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { 
  Container,
  Box,
  Typography,
  Stack
  } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'


function Policy() {

  const { t } = useTranslation()

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blueGrey[300]
      },
      background: {
        default: blueGrey[900]
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        bgcolor: blueGrey[900],
        mb: 10,
        mt: 10
        }}>
        <Container maxWidth="md">
          <Stack spacing={5} >

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - not completed - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('policy - not completed - description')}
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - orderable products - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                <ul>
                  <li>{t('policy - orderable products - description - pcb')}</li>
                  <li>{t('policy - orderable products - description - enclosure')}</li>
                </ul>
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - not orderable products - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('policy - not orderable products - description - summary')}
                <ul>
                  <li>{t('policy - not orderable products - description - keyswitch')}</li>
                  <li>{t('policy - not orderable products - description - keycap')}</li>
                  <li>{t('policy - not orderable products - description - Raspberry Pi Pico 1')}</li>
                  <li>{t('policy - not orderable products - description - soldering iron')}</li>
                  <li>{t('policy - not orderable products - description - solder')}</li>
                  <li>{t('policy - not orderable products - description - flax')}</li>
                  <li>{t('policy - not orderable products - description - philips screwdriver')}</li>
                  <li>{t('policy - not orderable products - description - pilers')}</li>
                </ul>
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - order to ship - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('policy - order to ship - description')}
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - shipping - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('policy - shipping - description - summary')}
                <ul>
                  <li>
                    {t('policy - shipping - description - how long')}
                  </li>
                  <li>
                    {t('policy - shipping - description - weekend')}
                  </li>
                </ul>
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - warranty - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('policy - warranty - description')}
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - assembly - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('policy - assembly - description')}
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('policy - privacy policy - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('policy - privacy policy - description')}
              </Typography>
            </Box>

          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Policy;
