import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { 
  Container,
  Box,
  Typography,
  Stack
  } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'


function Requirements() {

  const { t } = useTranslation()

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blueGrey[300]
      },
      background: {
        default: blueGrey[900]
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        bgcolor: blueGrey[900],
        mb: 10,
        mt: 10
        }}>
        <Container maxWidth="md">
          <Stack spacing={5} >
            <Box>
              <Typography 
                variant="h3"
                gutterBottom
                >
                {t('requirements - requirements')}
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h4"
                gutterBottom
                >
                {t('requirements - orderable products')}
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - pcb - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('requirements - pcb - description')}
              </Typography>
              <img src="/req/pcb.png" alt="pcb" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - enclosure - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('requirements - enclosure - description')}
              </Typography>
              <img src="/req/enclosure.png" alt="enclosure" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - pinheader - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('requirements - pinheader - description')}
              </Typography>
              <img src="/req/pinheader.png" alt="pinheader" />
            </Box>

            <Box>
              <Typography 
                variant="h4"
                gutterBottom
                >
                {t('requirements - not orderable products')}
              </Typography>
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - keyswitch - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('requirements - keyswitch - description')}
              </Typography>
              <img src="/req/keyswitch.png" alt="keyswitch" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - keycap - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('requirements - keycap - description')}
              </Typography>
              <img src="/req/keycap.png" alt="keycap" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - Raspberry Pi Pico 1 - title')}
              </Typography>
              <Typography 
                variant="body1"
                gutterBottom
                >
                {t('requirements - Raspberry Pi Pico 1 - description')}
              </Typography>
              <img src="/req/pico.png" alt="pico" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - solder - title')}
              </Typography>
              <img src="/req/solder.png" alt="solder" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - soldering iron - title')}
              </Typography>
              <img src="/req/soldering-iron.png" alt="soldering-iron" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - flax - title')}
              </Typography>
              <img src="/req/flax.png" alt="flax" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - pliers - title')}
              </Typography>
              <img src="/req/radiopenchi.png" alt="radiopenchi" />
            </Box>

            <Box>
              <Typography 
                variant="h5"
                gutterBottom
                >
                {t('requirements - screwdriver - title')}
              </Typography>
              <img src="/req/driver.png" alt="driver" />
            </Box>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Requirements;
