import { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { Button,
  Typography, 
  Card, 
  CardContent,
  CardMedia,
  CardActionArea,
  Container,
  Checkbox,
  IconButton,
  Collapse,
  Radio,
  RadioGroup,
  Box,
  FormControlLabel,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link
  } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { saveOptions, getOptions } from '../models/OptionsManager';
import { loadProject } from '../models/ProjectManager'
import { blueGrey } from '@mui/material/colors'
import { getIsOnsite, getCheckoutUrl } from '../models/PathManager'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import InfoIcon from '@mui/icons-material/Info'


function ItemsGrid(props) {
  
  if (Object.keys(props.items).length === 0) {
    return ""
  }

  return (
    <Paper sx={{ 
        mt: 5, 
        padding: 3,
        backgroundColor: blueGrey[900] // '#292932'
      }}
      elevation={0} 
      variant="outlined">
      <RadioGroup>
        <Typography
          variant="h5"
          sx={{
            mt: 0,
            mb: 2
          }}>
          { props.title }
        </Typography>
        <Grid
          container
          spacing={2}>
          {Object.keys(props.items).map((key, index) => (
            <Grid key={index} xs minHeight={160}>
              <ItemStack 
                data={props.items[key]} 
                key={key} 
                setfunc={props.setfunc} 
                itemkey={key} 
                selected={props.select_id===key} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Paper>
  )
}

function ItemStack(props) {
  const [cardColor, setCardColor] = useState('#FFF')
  const [cardColorBg, setCardColorBg] = useState('#222')
  const [expanded, setExpanded] = useState(false)
  const [price, setPrice] = useState("0")
  const [title, ] = useState(getValue(props.data.title))
  const [desc, ] = useState(getValue(props.data.desc))

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }))

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleSelect = () => {
    props.setfunc(props.itemkey)
  }
  
  function getValue(vals) {
    let value = ""
    for (let val of vals) {
      /*
      [
          {
              "lang": "ja",
              "value": "赤軸"
          },
          {
              "lang": "en",
              "value": "Red"
          }
      ]
      */
      if (val.lang === i18n.language) {
        value = val.value
        break
      }
    }
    return value
  }

  useEffect(() => {
    if (props.selected) {
      setCardColor('#FFF')
      setCardColorBg('#335')
    } else if (props.data.disable) {
      setCardColor('#777')
      setCardColorBg('#444')
    } else {
      setCardColor('#FFF')
      setCardColorBg('#222')
    }
  }, [props.selected, props.data.disable])
  
  useEffect(() => {
    if (props.data.price) {
      setPrice(props.data.price.toLocaleString())
    }
  }, [props.data.price])

  return (
    <Stack spacing={0}>
      <Card
        key={props.data.id}>
        <CardActionArea
          sx={{ 
            color: cardColor,
            backgroundColor: cardColorBg
          }}
          onClick={handleSelect}
          disabled={props.data.disable}>
          { 
            props.data.src ?
              <CardMedia
                component="img"
                height="200"
                image={props.data.src}
                alt={title}
              />
            :
              ""
          }
          <CardContent>
            <FormControlLabel 
              value={props.itemkey} 
              control={<Radio sx={{
                color: grey[800],
                '&.Mui-checked': {
                  color: "#00e676",
                }
              }} />} 
              label={title} 
              checked={props.selected}
              disabled={props.data.disable}
            />
            <Box align="right">
              <Typography gutterBottom variant="subtitle1" component="span">
                {price}&nbsp;
              </Typography>
              <Typography gutterBottom variant="caption" component="span">
                {props.data.currency}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {desc}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
      { 
        desc ?
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        :
          ""
      }
    </Stack>
  )
}


function PriceTable(props) {

  const { t } = useTranslation()

  return (
    <TableContainer sx={{ mt: 12 }} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", bgcolor: "primary.main" }}>{t('Title')}</TableCell>
            <TableCell sx={{ fontWeight: "bold", bgcolor: "primary.main"}}>{t('Name')}</TableCell>
            <TableCell sx={{ fontWeight: "bold", bgcolor: "primary.main"}} align="right">{t('Price ( JPY )')}</TableCell>
            <TableCell sx={{ fontWeight: "bold", bgcolor: "primary.main"}} align="right">{t('Pcs')}</TableCell>
            <TableCell sx={{ fontWeight: "bold", bgcolor: "primary.main"}} align="right">{t('Subtotal ( JPY )')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <PriceRow row={row} key={row.title} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ fontSize: 20, fontWeight: "bold", color: "white"}}>Total</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell sx={{ fontSize: 20, fontWeight: "bold", color: "white" }} align="right">{props.total}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

function PriceRow(props) {

  function getValue(vals) {
    let value = ""
    for (let val of vals) {
      if (val.lang === i18n.language) {
        value = val.value
        break
      }
    }
    return value
  }

  return (
    <>
      { props.row.subtotal !== 0 ?
          <TableRow key={getValue(props.row.title)}>
            <TableCell component="th" scope="row">{props.row.title}</TableCell>
            <TableCell>{props.row.name}</TableCell>
            <TableCell align="right">{props.row.price}</TableCell>
            <TableCell align="right">{props.row.pcs}</TableCell>
            <TableCell align="right">{props.row.subtotal}</TableCell>
          </TableRow>
        :
          ""
      }
    </>
  )
}

function Options() {

  let { projectId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  
  const [isOnsite, ] = useState(getIsOnsite(location.pathname))

  const [keyswitch_id, setKeyswitchId] = useState("KEYSWITCH_NONE")
  const [keycap_id, setKeycapId] = useState("KEYCAP_NONE")
  const [enclosure_id, setEnclosureId] = useState("ENCLOSURE_NONE")
  const [board_id, setBoardId] = useState("BOARD_MILLING")
  const [module_id, setModuleId] = useState("MODULE_NONE")
  const [assembly_id, setAssemblyId] = useState("ASSEMBLY_NONE")
  const [shipping_id, setShippingId] = useState("SHIPPING_CLICKPOST")

  const [keyswitchs, setKeyswitchs] = useState({})
  const [keycaps, setKeycaps] = useState({})
  const [enclosures, setEnclosures] = useState({})
  const [boards, setBoards] = useState({})
  const [modules, setModules] = useState({})
  const [assemblies, setAssemblies] = useState({})
  const [shippings, setShippings] = useState({})
  const [keycount, setKeycount] = useState(0)
  const [checked, setChecked] = useState(false)

  const { t } = useTranslation()
  // const [lang, setLang] = useState('ja')

  function getValue(vals) {
    let value = ""
    for (let val of vals) {
      if (val.lang === i18n.language) {
        value = val.value
        break
      }
    }
    return value
  }

  function getName(options, selected) {
    try {
      return getValue(options[selected].title)
    } catch (error) {
      return "-"
    }
  }

  function getPrice(options, selected) {
    try {
      return options[selected].price
    } catch (error) {
      return 0
    }
  }

  function getSubtotal(options, selected, pcs) {
    try {
      return options[selected].price * pcs
    } catch (error) {
      return 0
    }
  }

  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    async function doGetOptions() {
      const value = await getOptions(isOnsite)
      const _keyswitchs = {}
      const _keycaps = {}
      const _enclosures = {}
      const _boards = {}
      const _modules = {}
      const _assemblies = {}
      const _shippings = {}
      value.keyswitchs.sort((a, b) => a.sort - b.sort)
      value.keycaps.sort((a, b) => a.sort - b.sort)
      value.enclosures.sort((a, b) => a.sort - b.sort)
      value.boards.sort((a, b) => a.sort - b.sort)
      value.modules.sort((a, b) => a.sort - b.sort)
      value.assemblies.sort((a, b) => a.sort - b.sort)
      value.shippings.sort((a, b) => a.sort - b.sort)
      var item = null
      for (item of value.keyswitchs) {
        _keyswitchs[item.id] = item
      }
      for (item of value.keycaps) {
        _keycaps[item.id] = item
      }
      for (item of value.enclosures) {
        _enclosures[item.id] = item
      }
      for (item of value.boards) {
        _boards[item.id] = item
      }
      for (item of value.boards) {
        _boards[item.id] = item
      }
      for (item of value.modules) {
        _modules[item.id] = item
      }
      for (item of value.assemblies) {
        _assemblies[item.id] = item
      }
      for (item of value.shippings) {
        _shippings[item.id] = item
      }
      setKeyswitchs(_keyswitchs)
      setKeycaps(_keycaps)
      setEnclosures(_enclosures)
      setBoards(_boards)
      setModules(_modules)
      setAssemblies(_assemblies)
      setShippings(_shippings)
    }
    doGetOptions()
  }, [isOnsite])

  function saveHandler(event) {
    saveOptions(projectId, {
      keyswitch: keyswitchs[keyswitch_id],
      keycap: keycaps[keycap_id], 
      enclosure: enclosures[enclosure_id], 
      board: boards[board_id], 
      module: modules[board_id], 
      assembly: assemblies[assembly_id],
      shipping: shippings[shipping_id],
      total: total
    })
    navigate(getCheckoutUrl(location.pathname, projectId))
  }

  /*
  useEffect(() => {
    if (0 < Object.keys(keyswitchs).length) {
      updateRows("Keyswitchs", keyswitchs, keyswitch_id, keycount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyswitch_id, keyswitchs])

  useEffect(() => {
    if (0 < Object.keys(keycaps).length) {
      updateRows("Keycaps", keycaps, keycap_id, keycount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycap_id, keycaps])

  useEffect(() => {
    if (0 < Object.keys(enclosures).length) {
      updateRows("Enclosures", enclosures, enclosure_id, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enclosure_id, enclosures])

  useEffect(() => {
    if (0 < Object.keys(boards).length) {
      updateRows("Boards", boards, board_id, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board_id, boards])

  useEffect(() => {
    if (0 < Object.keys(modules).length) {
      updateRows("Modules", modules, module_id, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module_id, modules])

  useEffect(() => {
    if (0 < Object.keys(assemblies).length) {
      updateRows("Assemblies", assemblies, assembly_id, 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assembly_id, assemblies])
  */

  useEffect(() => {
    const _rows = [
      {
        title: t("Board"), 
        name: getName(boards, board_id), 
        pcs: 1, 
        price: getPrice(boards, board_id), 
        subtotal: getSubtotal(boards, board_id, 1)
      },
      {
        title: t("Keyswitch"), 
        name: getName(keyswitchs, keyswitch_id), 
        pcs: keycount, 
        price: getPrice(keyswitchs, keyswitch_id), 
        subtotal: getSubtotal(keyswitchs, keyswitch_id, keycount)
      },
      {
        title: t("Keycap"), 
        name: getName(keycaps, keycap_id), 
        pcs: keycount, 
        price: getPrice(keycaps, keycap_id), 
        subtotal: getSubtotal(keycaps, keycap_id, keycount)
      },
      {
        title: t("Enclosure"), 
        name: getName(enclosures, enclosure_id), 
        pcs: 1, 
        price: getPrice(enclosures, enclosure_id), 
        subtotal: getSubtotal(enclosures, enclosure_id, 1)
      },
      {
        title: t("Module"), 
        name: getName(modules, module_id), 
        pcs: 1, 
        price: getPrice(modules, module_id), 
        subtotal: getSubtotal(modules, module_id, 1)
      },
      {
        title: t("Assembly"), 
        name: getName(assemblies, assembly_id), 
        pcs: 1, 
        price: getPrice(assemblies, assembly_id), 
        subtotal: getSubtotal(assemblies, assembly_id, 1)
      },
      {
        title: t("Shipping"), 
        name: getName(shippings, shipping_id), 
        pcs: 1, 
        price: getPrice(shippings, shipping_id), 
        subtotal: getSubtotal(shippings, shipping_id, 1)
      }
    ]
    setTotal(getTotal(_rows))
    /*
    _rows.push({
      title: "Total", 
      name: "-", 
      pcs: "-", 
      price: total, 
      subtotal: total
    })
    */
    setRows(_rows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keyswitch_id, 
    keycap_id, 
    enclosure_id,
    board_id,
    module_id,
    assembly_id,
    shipping_id,
    keyswitchs,
    keycaps,
    enclosures,
    boards,
    assemblies,
    shippings,
    keycount
  ])

  function getTotal(_rows) {
    let total = 0
    for (let _row of _rows) {
      if (_row.title !== "Total") {
        total += _row.subtotal
      }
    }
    return total
  }

  function openHow() {
    window.open("/how/")
  }

  function handleChange() {
    let _checked = !checked
    setChecked(_checked)
  }

  useEffect(() => {
    async function doGet() {
      const json_data = await loadProject(projectId)
      setKeycount(json_data.keys.length)
    }
    doGet()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  return (
    <Container maxWidth="md">
      <ItemsGrid items={boards} setfunc={setBoardId} select_id={board_id} title={t("Board")}/>
      <ItemsGrid items={keyswitchs} setfunc={setKeyswitchId} select_id={keyswitch_id} title={t('Keyswitch')} />
      <ItemsGrid items={keycaps} setfunc={setKeycapId} select_id={keycap_id} title={t('Keycap')}/>
      <ItemsGrid items={enclosures} setfunc={setEnclosureId} select_id={enclosure_id} title={t('Enclosure')}/>
      <ItemsGrid items={modules} setfunc={setModuleId} select_id={module_id} title={t('Module')}/>
      <ItemsGrid items={assemblies} setfunc={setAssemblyId} select_id={assembly_id} title={t('Assembly')}/>
      <ItemsGrid items={shippings} setfunc={setShippingId} select_id={shipping_id} title={t('Shipping')}/>
      <PriceTable rows={rows} total={total} />
      <Paper sx={{ 
          mt: 10, 
          mb: 10,
          padding: 5,
          backgroundColor: blueGrey[900] // '#292932'
        }}
        elevation={0} 
        variant="outlined">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          mt: 1 }}>
          <Button 
            onClick={saveHandler}
            variant="contained"
            color="success"
            disabled={!checked}
            sx={{ mb: 2 }}>
            {t('Checkout')}
          </Button>
          <Stack direction="row" spacing={0.5}>
            <Link key="how" href="#" variant="body2" 
              onClick={openHow} 
              sx={{ mt: 1 }}>
              <Stack direction="row" spacing={1}>
                <InfoIcon />
                <Typography>ご確認ください</Typography>
              </Stack>
            </Link>
            <FormControlLabel
              label="承諾"
              control={<Checkbox checked={checked} onChange={handleChange} />}
            />
          </Stack>
        </Box>
      </Paper>
    </Container>
  );
}

export default Options;
