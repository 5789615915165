import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { 
  Container,
  Box,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Link
  } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey, red } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import LangSelector from './LangSelector'


function Docs() {

  const { t } = useTranslation()

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blueGrey[300]
      },
      background: {
        default: blueGrey[900]
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        bgcolor: blueGrey[900],
        mb: 10,
        mt: 10
        }}>
        <Container maxWidth="sm">
          <List>
            <ListItem>
              <ListItemText>
                <Link href="/assembly/" target="_blank" rel="noopener">{t('Assembly')}</Link>
              </ListItemText>
              <ListItemText>
                <Link href="/keymap/upload/">{t('Change Firmware')}</Link>
              </ListItemText>
              <ListItemText>
                <Link href="/requirements/" target="_blank" rel="noopener">{t('Requirements')}</Link>
              </ListItemText>
              <ListItemText>
                <Link href="/policy/" target="_blank" rel="noopener">{t('Policy')}</Link>
              </ListItemText>
            </ListItem>
          </List>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Docs;
