import SVG from "@svgdotjs/svg.js/src/svg"
import { getData, KEY_OPTIONS_DICT } from './Kbdata'
import { checkIfOutOfRange, showWarning } from './Range'
// import { blueGrey } from '@mui/material/colors'


let svg_container = null
let svg_hidden_container = null
let bg = null
let KEY_SIZE = null
let KEY_SPACE = null
let KEY_SCALE = null
let KEY_RECT_SIZE = null
let TYPE = null
const DROP_ANIM_DURATION = 100
const KEY_RADIUS = 4
let KEY_START_X = 200
const KEY_START_Y = 50
const KEY_TXT_SIZE = 17
const KEY_TXT_FONT = 'helvetica'
const KEY_TXT_Y_TOP = 20
const KEY_TXT_Y_BOTTOM = 40
const keys = []
let onSelectAction = undefined
const dragging = {
  element: undefined,
  startx: undefined,
  starty: undefined,
  downx: undefined,
  downy: undefined,
  moved: false
}
const selected = {
  element: undefined,
  str_top: '',
  str_bottom: '',
  width_unit: undefined,
  margin_unit: undefined,
  empty: undefined
}
/*
const COLORS = {
  rows: [
    '#00FF00',
    '#AAFF00',
    '#00FFAA',
    '#00FF00',
    '#AAFF00',
    '#00FFAA',
    '#00FF00',
    '#AAFF00',
    '#00FFAA',
    '#00FF00',
    '#AAFF00',
    '#00FFAA',
    '#00FF00',
    '#AAFF00',
    '#00FFAA',
    '#00FF00',
    '#AAFF00',
    '#00FFAA',
    '#00FF00',
    '#AAFF00',
    '#00FFAA'
  ],
  cols: [
    '#0000FF',
    '#8800FF',
    '#0088FF',
    '#0000FF',
    '#8800FF',
    '#0088FF',
    '#0000FF',
    '#8800FF',
    '#0088FF',
    '#0000FF',
    '#8800FF',
    '#0088FF',
    '#0000FF',
    '#8800FF',
    '#0088FF',
    '#0000FF',
    '#8800FF',
    '#0088FF',
    '#0000FF',
    '#8800FF',
    '#0088FF'
  ]
}
*/

const COLOR_EMPTY = '#2E3C43' // '#2E3C43' '#5e6b72' blueGrey[600]
const COLOR_REAL = '#37474f' // 37474f
const TOP_LINE = '#999' // f06

function setSvg(ref) {
  svg_container = SVG().addTo(ref)
  var nst = svg_container.nested()
  var w = document.documentElement.clientWidth
  var h = document.documentElement.clientHeight
  bg = nst.rect(w, h)
  //var color = new SVG.Color({ h: 0, s: 0, l: 35 })
  bg.attr({
    fill: '#263238' // 263238
  })
  nst.move(0, 0).line(0, 0, w, 0).stroke({ width: 1, color: TOP_LINE })
}

function setHiddenSvg(ref) {
  svg_hidden_container = SVG().addTo(ref)
}

function setEditorSize() {
  var cw = document.documentElement.clientWidth
  var ch = document.documentElement.clientHeight
  if (svg_container) {
    svg_container.size(cw, ch)
    if (bg) {
      bg.width(svg_container.width())
      bg.height(svg_container.height())
    }
  }
}

function setOnSelectAction(fn) {
  onSelectAction = fn
}

function initEditor(kbdata) {
  // kbdataのデータで描画する
  /*
  {
    "board": {
      "w": 55,
      "h": 44,
      "key": {
        "w": 16,
        "h": 16
      },
      "module": {
        "x": 27.5,
        "y": 12.5,
        "w": 21,
        "h": 51
      }
    }
  */
  TYPE = kbdata.type
  KEY_SIZE = kbdata.vars.key_size
  KEY_SPACE = kbdata.vars.key_space
  KEY_SCALE = kbdata.vars.key_scale
  KEY_RECT_SIZE = KEY_SIZE - KEY_SPACE
  // キースイッチ
  for (var i = 0; i < kbdata.keys.length; i++) {
    var item = kbdata.keys[i]
    var x = (item.x * KEY_SCALE) - ((item.w * KEY_SCALE) / 2)
    var y = (item.y * KEY_SCALE) - ((item.h * KEY_SCALE) / 2)
    var w = (item.w * KEY_SCALE + KEY_SPACE) / KEY_SIZE
    create_key(
      KEY_START_X + x,
      KEY_START_Y + y,
      w,
      item.code,
      item.empty
    )
  }
  updateKeyStartX()
  centeringHolizontally()
  //SVG.on(window, 'mouseup', mouseup_window)
  svg_container.on('mouseup', mouseup_window)
}

function create_key(x, y, width_unit, key_code, empty) {
  // キーを追加する
  var key_nst = svg_container.nested()
  key_nst.move(x, y)
  key_nst.on('mousedown', mousedown_key)
  key_nst.on('mouseup', mouseup_key)
  key_nst.on('mouseover', mouseover_key)
  key_nst.on('mouseout', mouseout_key)
  keys.push(key_nst)
  var key_w = KEY_SIZE * width_unit - KEY_SPACE
  var key_h = KEY_SIZE - KEY_SPACE
  var key_bg = key_nst.rect(key_w, key_h).radius(KEY_RADIUS)
  var key_area = key_nst.rect(key_w, key_h).radius(KEY_RADIUS)
  key_nst.data('code', key_code)
  key_nst.data('empty', empty)
  key_nst.data('unit', width_unit)
  if(empty) {
    key_bg.attr({
      fill: COLOR_EMPTY
    })
    /*
    key_nst.rect(key_w - 2, key_h - 2).radius(KEY_RADIUS - 1)
      .move(1, 1)
      .attr({
        fill: blueGrey[900]
      })
    */
  } else {
    key_bg.attr({
      fill: COLOR_REAL, 
    })
  }
  key_area.attr({
    'fill-opacity': 0
  })
  // key label
  var text_color = new SVG.Color({ h: 0, s: 0, l: 100 })
  var top_str = ""
  var bottom_str = ""
  if (key_code) {
    top_str = KEY_OPTIONS_DICT[key_code].top
    bottom_str = KEY_OPTIONS_DICT[key_code].bottom
  }
  var top_text = key_nst.text(top_str)
  var bottom_text = key_nst.text(bottom_str)
  top_text.font({
    x: key_w / 2,
    y: KEY_TXT_Y_TOP,
    family: KEY_TXT_FONT,
    size: KEY_TXT_SIZE,
    anchor: 'middle',
    leading: '0em',
    fill: text_color
  })
  bottom_text.font({
    x: key_w / 2,
    y: KEY_TXT_Y_BOTTOM,
    family: KEY_TXT_FONT,
    size: KEY_TXT_SIZE,
    anchor: 'middle',
    leading: '0em',
    fill: text_color
  })
  key_area.front()
}

function mouseup_key(event) {
  unselect_key()
  if ( dragging.downx === event.pageX && dragging.downy === event.pageY ) {
    // 移動しなかった、つまりキースイッチのドラッグドロップを意図したものではなく選択を意図したものと解釈する。
    // var key_nst = SVG(event.path[1])
    var key_nst = SVG(event.target.parentElement)
    // pass key
    select_key(key_nst)
  }
}

function mousedown_key(event) {
  //console.log(event.view.SVG)
  // var key_nst = SVG(event.path[1])
  var key_nst = SVG(event.target.parentElement)
  key_nst.off('mousedown', mousedown_key)
  key_nst.front()
  dragging.startx = event.clientX - key_nst.x()
  dragging.starty = event.clientY - key_nst.y()
  dragging.downx = event.pageX
  dragging.downy = event.pageY
  if (dragging.element === undefined) {
    dragging.element = key_nst
    // SVG.on(window, 'mousemove', mousemove)
    svg_container.on('mousemove', mousemove)
  }
}

function mouseover_key(event) {
  var key_nst = SVG(event.target)
  key_nst.fill({ color: '#f06', opacity: 0.6 })
}

function mouseout_key(event) {
  var key_nst = SVG(event.target)
  key_nst.fill({ opacity: 0 })
}

function select_key(elem) {
  selected.element = elem
  selected.width_unit = (elem.last().width() + KEY_SPACE) / KEY_SIZE
  // selected.margin_unit = 0
  selected.str_top = selected.element.get(1).text()
  selected.str_bottom = selected.element.get(2).text()
  elem.last().attr({
    'fill-opacity': 0.3
  })
  onSelectAction({
    width: selected.width_unit,
    // margin: selected.margin_unit,
    code: selected.element.data('code'),
    empty: selected.element.data('empty')
  })
}

function unselect_key() {
  selected.element = undefined
  selected.width_unit = ''
  selected.margin_unit = ''
  selected.str_top = ''
  selected.str_bottom = ''
  for (var i = 0; i < keys.length; i++) {
    keys[i].last().attr({
      'fill-opacity': 0
    })
  }
  //offSelectAction()
}

function mousemove(event) {
  if (dragging.element !== undefined) {
    dragging.element.move(event.clientX - dragging.startx, event.clientY - dragging.starty)
    dragging.moved = true
  }
}

function mouseup_window(event) {
  if (dragging.element !== undefined) {
    dragging.element.on('mousedown', mousedown_key)
    // SVG.off(window, 'mousemove', mousemove)
    svg_container.off('mousemove', mousemove)
    for (var i0 = 1; i0 < 10; i0++) {
      var cy = KEY_START_Y + i0 * KEY_SIZE
      if (dragging.element.y() < cy) {
        dragging.element.y(KEY_START_Y + (i0 - 1) * KEY_SIZE)
        break
      }
    }
    setTimeout(() => {
      reorganize()
    }, 100)
  }
  var select = false
  if (dragging.element !== undefined && dragging.element.inside(event.offsetX - dragging.element.x(), event.offsetY - dragging.element.y())) {
    select = true
  }
  if (!select) {
    unselect_key()
  }
  dragging.element = undefined
  dragging.moved = false
}

function add_key() {
  if(!warnOutOfRange()) {
    let x = 0
    let y = 0
    if (0 < keys.length) {
      x = KEY_START_X
      y = keys[keys.length - 1].y() + KEY_SIZE
    } else {
      x = KEY_START_X
      y = KEY_START_Y
    }
    create_key(
      x,
      y,
      1,
      'KC_NO',
      false
    )
    setTimeout(() => {
      warnOutOfRange()
    }, 100)
  }
}

function remove_key(event) {
  var key_nst = SVG(event.target.parentElement.parentElement)
  var idx = -1
  for (var i = 0; i < keys.length; i++) {
    if (key_nst === keys[i]) {
      idx = i
      break
    }
  }
  key_nst.remove()
  if (idx !== -1) {
    keys.splice(idx, 1)
  }
  reorganize()
}

function enter_deleting_keys() {
  // SVG.off(window, 'mouseup', mouseup_window)
  svg_container.off('mouseup', mouseup_window)
  const DELETE_MARK_LINE_LENGTH = 14
  const DELETE_MARK_LINE_WIDTH = 2
  for (var i = 0; i < keys.length; i++) {
    keys[i].off('mousedown', mousedown_key)
    keys[i].off('mouseup', mouseup_key)
    keys[i].off('mouseover', mouseover_key)
    keys[i].off('mouseout', mouseout_key)
    var mark = keys[i].nested()
    mark.rect(keys[i].first().width(), keys[i].first().height())
      .radius(KEY_RADIUS)
      .attr({ fill: '#e62e56' })
      .opacity(0.7)
    const lx = (keys[i].first().width() - DELETE_MARK_LINE_LENGTH) / 2
    const ly = (keys[i].first().height() - DELETE_MARK_LINE_LENGTH) / 2
    mark.line(0, DELETE_MARK_LINE_LENGTH, DELETE_MARK_LINE_LENGTH, 0)
      .move(lx, ly)
      .stroke({ color: '#fff', width: DELETE_MARK_LINE_WIDTH, linecap: 'round' })
    mark.line(0, 0, DELETE_MARK_LINE_LENGTH, DELETE_MARK_LINE_LENGTH)
      .move(lx, ly)
      .stroke({ color: '#fff', width: DELETE_MARK_LINE_WIDTH, linecap: 'round' })
    mark.on('mouseup', remove_key)
  }
}

function stop_deleting_keys() {
  for (var i = 0; i < keys.length; i++) {
    keys[i].last().remove()
    keys[i].on('mousedown', mousedown_key)
    keys[i].on('mouseup', mouseup_key)
    keys[i].on('mouseover', mouseover_key)
    keys[i].on('mouseout', mouseout_key)
  }
  // SVG.on(window, 'mouseup', mouseup_window)
  svg_container.on('mouseup', mouseup_window)
}

function modify_key(data) {
  // キーの文字や大きさを変更する
  var width = KEY_SIZE * data.width - KEY_SPACE
  var height = KEY_SIZE - KEY_SPACE
  selected.element.first().size(width, height)
  selected.element.last().size(width, height)
  selected.element.data('empty', data.empty)
  selected.element.data('unit', data.width)
  if (data.empty) {
    selected.element.first().attr({'fill': COLOR_EMPTY})
    selected.element.get(1).text('')
    selected.element.get(2).text('')
    selected.element.data('code', 'KC_NO')
  } else {
    selected.element.first().attr({'fill': COLOR_REAL})
    selected.element.get(1).text(data.top)
    selected.element.get(2).text(data.bottom)
    selected.element.get(1).font("x", width / 2)
    selected.element.get(2).font("x", width / 2)
    selected.element.data('code', data.code)
  }
  setTimeout(() => {
    reorganize()
  }, 100)
}

function modify_keycode(data) {
  // キーの文字を変更する
  // キーの配置などに関連する部分を書き換えないのがその目的
  selected.element.get(1).text(data.top)
  selected.element.get(2).text(data.bottom)
  selected.element.data('code', data.code)
}

/*
function remove_all_keys() {
  // キースイッチを削除
  for (var i = 0; i < keys.length; i++) {
    keys[i].remove()
  }
  while (keys.length) {
    keys.pop()
  }
}
*/
// arrange keys again right after dropping and size editing
function reorganize() {
  keys.sort(function (a, b) {
    return a.x() - b.x()
  })
  keys.sort(function (a, b) {
    return a.y() - b.y()
  })
  var cx = KEY_START_X
  var cy = KEY_START_Y
  for (var i = 0; i < keys.length; i++) {
    if (cy < keys[i].y()) {
      // change row
      cx = KEY_START_X
    }
    if (cx !== keys[i].x()) {
      keys[i].animate(DROP_ANIM_DURATION).x(cx)
    }
    // for next action
    cx += keys[i].first().width() + KEY_SPACE
    cy = keys[i].y()
  }
  updateKeyStartX()
  resetVertically()
  centeringHolizontally()
  console.log("reorganize")
  setTimeout(() => {
    warnOutOfRange()
  }, 100)
}

function warnOutOfRange() {
  if (checkIfOutOfRange(
    keys, 
    TYPE, 
    KEY_START_X, 
    KEY_START_Y, 
    KEY_SCALE
  )) {
    showWarning(
      svg_container, 
      TYPE, 
      KEY_START_X, 
      KEY_START_Y, 
      KEY_SCALE
    )
    return true
  } else {
    return false
  }
}

function updateKeyStartX() {
  var cx = KEY_START_X
  var cy = KEY_START_Y
  var sxs = []
  var exs = []
  for (var i = 0; i < keys.length; i++) {
    if (cy < keys[i].y()) {
      // change row
      cx = KEY_START_X
    }
    // for centering holizontally
    sxs.push(cx)
    exs.push(cx + keys[i].first().width())
    // for next action
    cx += keys[i].first().width() + KEY_SPACE
    cy = keys[i].y()
  }
  let kb_width = 0
  if (keys.length) {
    let max = 0
    let min = 0
    if (exs.length) {
      max = Math.max(...exs)
    }
    if (sxs.length) {
      min = Math.min(...sxs)
    }
    kb_width = max - min
  } else {
    // キーがまったくない場合でもデフォルトのキー１個の幅を設定する
    kb_width = KEY_RECT_SIZE
  }
  KEY_START_X = (svg_container.width() - kb_width) / 2
}

function centeringHolizontally() {
  let cx = KEY_START_X
  let cy = KEY_START_Y
  for (var i = 0; i < keys.length; i++) {
    if (cy < keys[i].y()) {
      cx = KEY_START_X
    }
    if (cx !== keys[i].x()) {
      keys[i].animate(DROP_ANIM_DURATION).x(cx)
    }
    cx += keys[i].first().width() + KEY_SPACE
    cy = keys[i].y()
  }
}

function resetVertically() {
  let top_most_y = 1000
  let i = 0
  for (i = 0; i < keys.length; i++) {
    if (keys[i].y() < top_most_y) {
      top_most_y = keys[i].y()
    }
  }
  const diff = top_most_y - KEY_START_Y
  if (0 < diff) {
    for (i = 0; i < keys.length; i++) {
      keys[i].animate(DROP_ANIM_DURATION).y(keys[i].y() - diff)
    }
  }
}

function getKbdata() {
  reorganize()
  return getData(
    TYPE,
    keys, 
    KEY_START_X,
    KEY_START_Y,
    KEY_SCALE,
    KEY_SIZE,
    KEY_SPACE
  )
}

function getKeycodeResettedKbdata(kbdata) {
  for (var i = 0; i < keys.length; i++) {
    if (!keys[i].data('empty')) {
      kbdata.keys[i].code = keys[i].data('code')
    }
  }
  return kbdata
}

function getSvg(kbdata) {
  //const hidden_kbdata = getKbdata()
  var kb_width = 0
  var kb_height = 0
  // kbdataのデータで描画する
  KEY_SIZE = kbdata.vars.key_size
  KEY_SPACE = kbdata.vars.key_space
  KEY_SCALE = kbdata.vars.key_scale
  // キースイッチ
  for (var i = 0; i < kbdata.keys.length; i++) {
    var item = kbdata.keys[i]
    var x = (item.x * KEY_SCALE) - ((item.w * KEY_SCALE) / 2) + KEY_SPACE
    var y = (item.y * KEY_SCALE) - ((item.h * KEY_SCALE) / 2) + KEY_SPACE
    var w = (item.w * KEY_SCALE + KEY_SPACE) / KEY_SIZE
    var key_nst = svg_hidden_container.nested()
    key_nst.move(x, y)
    var key_w = KEY_SIZE * w - KEY_SPACE
    var key_h = KEY_SIZE - KEY_SPACE
    var key_bg = key_nst.rect(key_w, key_h).radius(KEY_RADIUS)
    var key_area = key_nst.rect(key_w, key_h).radius(KEY_RADIUS)
    if(item.empty) {
      key_bg.attr({
        fill: COLOR_EMPTY, 
      })
    } else {
      key_bg.attr({
        fill: COLOR_REAL, 
      })
    }
    key_area.attr({
      'fill-opacity': 0
    })
    // key label
    var text_color = new SVG.Color({ h: 0, s: 0, l: 100 })
    var top_str = ""
    var bottom_str = ""
    if (item.code) {
      top_str = KEY_OPTIONS_DICT[item.code].top
      bottom_str = KEY_OPTIONS_DICT[item.code].bottom
    }
    var top_text = key_nst.text(top_str)
    var bottom_text = key_nst.text(bottom_str)
    top_text.font({
      x: key_w / 2,
      y: KEY_TXT_Y_TOP,
      family: KEY_TXT_FONT,
      size: KEY_TXT_SIZE,
      anchor: 'middle',
      leading: '0em',
      fill: text_color
    })
    bottom_text.font({
      x: key_w / 2,
      y: KEY_TXT_Y_BOTTOM,
      family: KEY_TXT_FONT,
      size: KEY_TXT_SIZE,
      anchor: 'middle',
      leading: '0em',
      fill: text_color
    })
    var kb_height_current = y + key_h + KEY_SPACE
    var kb_width_current = x + key_w + KEY_SPACE
    if (kb_height < kb_height_current) {
      kb_height = kb_height_current
    }
    if (kb_width < kb_width_current) {
      kb_width = kb_width_current
    }
  }
  svg_hidden_container.size(kb_width, kb_height)
  return svg_hidden_container.svg()
}

export { setSvg, 
  setEditorSize, 
  initEditor, 
  getKbdata, 
  add_key, 
  enter_deleting_keys, 
  stop_deleting_keys, 
  setOnSelectAction,
  modify_key,
  unselect_key,
  getSvg,
  setHiddenSvg,
  modify_keycode,
  getKeycodeResettedKbdata,
  warnOutOfRange
}

