import * as React from 'react'
import { useState, useEffect } from 'react'
import { 
  Container, 
  Button, 
  Box,
  TextField
 } from '@mui/material'
import { createCustomProject } from '../models/ProjectManager'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const sample_kbdata = {
  "version": 2,
  "type": 7,
  "vars": {
    "key_size": 57,
    "key_space": 9,
    "key_scale": 3
  },
  "keys": [
    {
      "x": 8,
      "y": 8,
      "w": 16,
      "h": 16,
      "bx": 11,
      "by": 33,
      "bw": 18,
      "bh": 18,
      "code": "KC_0",
      "empty": false,
      "unit": 1,
      "net": [
        "GND4",
        "GP4"
      ]
    }
  ],
  "rotaryencoders": [],
  "board": {
    "w": 55,
    "h": 44,
    "key": {
      "w": 16,
      "h": 16
    },
    "module": {
      "x": 27.5,
      "y": 12.5,
      "w": 21,
      "h": 51
    }
  }
}

function Upload() {
  
  let navigate = useNavigate()
  const { t } = useTranslation()  
  
  const [kbdatastr, setKbdataStr] = useState(JSON.stringify(sample_kbdata, null , "\t"))
  const [kbdata, setKbdata] = useState(null)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setDisabled(true)
    if (kbdatastr !== "") {
      var parsed = {}
      try {
        parsed = JSON.parse(kbdatastr)
      } catch (error) {
        //
      } finally {
        if (parsed.keys && parsed.board && parsed.type) {
          parsed.upload = true
          setDisabled(false)
          setKbdata(parsed)
        }
      }
    }
  }, [kbdatastr])

  async function handleUpload() {
    if (kbdata) {
      var projectId = await createCustomProject(kbdata)
      navigate('/keymap/edit/' + projectId)
    }
  }
  
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <TextField
        label="kbdata.json"
        value={kbdatastr}
        multiline
        maxRows={15}
        minRows={15}
        fullWidth={true}
        onChange={(e)=>{
          setKbdataStr(e.target.value)
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
        <Button onClick={handleUpload} variant="outlined" disabled={disabled}
          sx={{ m: 2 }}>
          {t('UPLOAD')}
        </Button>
      </Box>
    </Container>
  )
}


export default Upload


