import { 
  Toolbar,
  Typography,
  Breadcrumbs,
  Link,
} from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import { Outlet, useMatches, useLocation, useNavigate } from "react-router-dom"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import LangSelector from './LangSelector'
import { getHomeUrl, getPath, getUrl } from '../models/PathManager'
import { useTranslation } from 'react-i18next'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blueGrey[300]
    },
    background: {
      default: blueGrey[900]
    }
  }
})

function Crumbs(props) {
  console.log("Crumbs matches", props.matches)

  const { t } = useTranslation()

  const items = [
    [t("HOME"), ""], 
    [t("UPLOAD"), "/keymap/upload"], 
    [t("EDIT"), "/keymap/edit"], 
    [t("DOWNLOAD"), "/keymap/firm"]
  ]
  let projectId = null
  if (props.matches[1].params && props.matches[1].params.projectId) {
    projectId = props.matches[1].params.projectId
  }
  console.log("projectId", projectId)
  let path = props.matches[1].pathname
  path = getPath(path, projectId)
  console.log("path", path)
  const breadcrumbs = []
  for (let item of items) {
    let clone = Object.assign({}, item)
    if (item[0] === t("HOME")) {
      clone[1] = getHomeUrl(props.matches[1].pathname)
    } else if (item[0] === t("UPLOAD")) {
      // nothing to do
    } else {
      clone[1] = clone[1] + "/" + projectId
      console.log("clone", clone)
    }
    breadcrumbs.push(clone)
    if (item[1] === path) {
      breadcrumbs[breadcrumbs.length - 1][1] = null
      break
    }
  }
  return (
    <Breadcrumbs 
      sx={{
        mt: 3,
        ml: 2
      }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb">
      {breadcrumbs.map( breadcrumb => (
        (
          breadcrumb[1] ?
            <Link key={breadcrumb[0]} href={breadcrumb[1]}>{breadcrumb[0]}</Link>
            :
            <Typography key={breadcrumb[0]}>{breadcrumb[0]}</Typography>
        )
      ))}
    </Breadcrumbs>
  )
}


function Keymap(props) {

  const matches = useMatches()
  const navigate = useNavigate()

  const { pathname } = useLocation()

  useEffect(() => {
    console.log("pathname: " + pathname)
    if (pathname === "/keymap/") {
      navigate('/keymap/upload')
    }
  }, [pathname, navigate])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toolbar>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ flexGrow: 1 }}>
          {1 < matches.length
            ? <Crumbs matches={matches} />
            : ""
          }
        </Typography>
        <LangSelector />
      </Toolbar>
      <Outlet />
    </ThemeProvider>
  )
}

export default Keymap
