import { 
  Toolbar,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  IconButton,
  DialogContentText,
  Box,
  Divider
} from '@mui/material'
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Outlet, useMatches, useLocation, useParams, useNavigate } from "react-router-dom"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { QRCodeSVG } from 'qrcode.react'
import { getProjectExists, getHasOrdered } from '../models/ProjectManager'
import { getHomeUrl, getPagename, getUrl } from '../models/PathManager'
import LangSelector from './LangSelector'
import { useTranslation } from 'react-i18next'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blueGrey[300]
    },
    background: {
      default: blueGrey[900]
    }
  }
})

function Head() {

  const { t } = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toolbar>
        <Link key="home" href="/" sx={{ mt: 4, ml: 2, flexGrow: 1 }}>{t('HOME')}</Link>
        <LangSelector />
      </Toolbar>
      <Outlet />
    </ThemeProvider>
  )
}

export default Head
