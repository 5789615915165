import { constants } from './Constants'

function getIsOnsite(currentpath) {
  return 0 <= currentpath.indexOf("/" + constants.LITE_PATH + "/")
}

function getUrl(destpath, currentpath, projectId) {
  if (getIsOnsite(currentpath)) {
    const paths = ["/", constants.LITE_PATH, "/", destpath]
    if (destpath) {
      paths.push("/")
    }
    if (projectId) {
      paths.push(projectId)
    }
    return paths.join("")
  } else {
    const paths = ["/", destpath]
    if (destpath) {
      paths.push("/")
    }
    if (projectId) {
      paths.push(projectId)
    }
    return paths.join("") 
  }
}

function getHomeUrl(currentpath) {
  return getUrl("", currentpath)
}

function getEditorUrl(currentpath, projectId) {
  return getUrl("editor", currentpath, projectId)
}

function getProjectUrl(currentpath, projectId) {
  return getUrl("project", currentpath, projectId)
}

function getOptionsUrl(currentpath, projectId) {
  return getUrl("options", currentpath, projectId)
}

function getCheckoutUrl(currentpath, projectId) {
  return getUrl("checkout", currentpath, projectId)
}

function getPagename(thepath, projectId) {
  if (projectId) {
    thepath = thepath.replace(projectId, "")
  }
  if (getIsOnsite(thepath)) {
    thepath = thepath.replace(constants.LITE_PATH, "")
  }
  return thepath.replaceAll("/", "") 
}

function getPath(thepath, projectId) {
  if (projectId) {
    thepath = thepath.replace(projectId, "")
  }
  if (getIsOnsite(thepath)) {
    thepath = thepath.replace(constants.LITE_PATH, "")
  }
  return thepath.replace(/\/$/, "")
}

export { getIsOnsite,
  getHomeUrl,
  getEditorUrl,
  getProjectUrl,
  getOptionsUrl,
  getCheckoutUrl,
  getPagename,
  getUrl,
  getPath
}
