import { useState, useEffect, useRef } from 'react'
import React from 'react';
import "@babylonjs/loaders/STL"
import { ArcRotateCamera, 
  SceneLoader, 
  Color3, 
  StandardMaterial, 
  HemisphericLight, 
  Vector3 } from "@babylonjs/core"
import ThreeDScene from "./ThreeDScene"
import { getEnclosureStlFile, 
  getKeycapsStlFile, 
  getKeyswitchsStlFile, 
  getAxisStlFile, 
  getModuleStlFile,
  getPinheaderPinStlFile,
  getPinheaderPlasticStlFile,
  getUsbStlFile,
  getPcbStlFile, 
  getHasStlStatus,
  getStlStatus,
  generateStl } from '../models/ThreeDManager'
import { 
  Box,
  Typography, 
  ToggleButton, 
  ToggleButtonGroup } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.label}</Typography>
      </Box>
    </Box>
  )
}


export default function ThreeD(props) {
  
  const [projectId, ] = useState(props.projectId)
  const [stlStatus, setStlStatus] = useState(null)
  const [stlFailed, setStlFailed] = useState(false)
  const [stlWatchingStatus, setStlWatchingStatus] = useState(false)
  const [stlGenerated, setStlGenerated] = useState(false)

  const { t } = useTranslation()
  
  let stlTimer = useRef()
  
  
  function intervalStlStatus() {
    getStlStatus(projectId).then(data => {
      console.log('getStlStatus', data)
      setStlStatus(data)
    })
  }
  
  
  function callGetStl() {
    generateStl(projectId)
    setStlWatchingStatus(true)
  }

  function getStatusWithProgress() {
    const status = [
      "started",
      "cad",
      "stl",
      "completed"
    ]
    if (0 <= status.indexOf(stlStatus)) {
      return (status.indexOf(stlStatus) + 1) + '/' + status.length
    }
    return ""
  }
  
  useEffect(() => {
    getHasStlStatus(projectId).then(data => {
      console.log('getHasStlStatus', data)
      if (data === true) {
        setStlWatchingStatus(true)
      } else {
        // status.jsonは存在しないのでSTLの生成を行う。
        callGetStl()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])
  
  
  useEffect(() => {
    if (stlWatchingStatus === true) {
      stlTimer.current = setInterval(intervalStlStatus, 3000)
    } else {
      clearInterval(stlTimer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stlWatchingStatus])
  
  
  useEffect(() => {
    console.log('stlStatus', stlStatus)
    if (stlStatus === "completed" || stlStatus === "error") {
      setStlWatchingStatus(false)
      if (stlStatus === "completed") {
        setStlGenerated(true)
      } else {
        setStlFailed(true)
      }
    }
    /*
    if (stlStatus === "completed" || stlStatus === "error") {
      setStlWatchingStatus(false)
      checkStlUpdated(projectId).then(data => {
        if (data === false) {
          // 処理は終わっているが、status.jsonはkbdata.jsonより古いのでSTLファイルは古い情報で生成されたことになる
          // なので、再度STLの生成を行う。
          setStlGenerated(false)
          callGetStl()
        } else if (data === true) {
          if (stlStatus === "completed") {
            // setStlWatchingStatus(false)
            setStlGenerated(true)
          } else if (stlStatus === "error") {
            setStlFailed(true)
          }
        }
      })
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stlStatus, projectId])
  
  
  useEffect(() => {
    console.log("stlGenerated " + stlGenerated)
    props.setDone(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stlGenerated, props])
  
  
  // --------------------- UI
  
  
  const [show3DElems, setShow3DElems] = useState({
    enclosure: true,
    pcb: true,
    keycaps: true,
    keyswitchs: true,
    module: true,
    pinheader_pin: true,
    pinheader_plastic: true
  })
  
  function showHandle(event) {
    console.log("event.target.value ---------------- " + event.target.value)
    const copiedObj = Object.assign({}, show3DElems)
    copiedObj[event.target.value] = !copiedObj[event.target.value]
    setShow3DElems(copiedObj)
  }


  // --------------------- Show 3D model----------------------------------
  
  
  const onSceneReady = (scene) => {
    const canvas = scene.getEngine().getRenderingCanvas();
  
    const camera = new ArcRotateCamera(
      "camera", 
      -Math.PI / 2, 
      Math.PI / 2.5, 
      10, 
      new Vector3(0, 0, 0),
      scene
    );
    
    camera.mode = camera.ORTHOGRAPHIC_CAMERA;
    
    camera.orthoTop = 50;
    camera.orthoBottom = -50;
    camera.orthoLeft = -100;
    camera.orthoRight = 100;
    
    camera.setPosition(new Vector3(100, 100, -100));
    camera.attachControl(canvas, true);
  
    var light = new HemisphericLight("light", new Vector3(0, 1, -1), scene);
    light.intensity = 1;
    camera.setTarget(new Vector3(0, 1, 0));
    
    var root = "";
    
    if(show3DElems.keycaps) {
      getKeycapsStlFile(projectId).then(data => {
        var keycaps_stl_url = data;
        SceneLoader.LoadAssetContainer(root, keycaps_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(0.4, 0.4, 0.4);
          material.specularColor = new Color3(0.1, 0.1, 0.1);
          meshe.material = material;
        });
      });
    }
    
    if(show3DElems.keyswitchs) {
      getKeyswitchsStlFile(projectId).then(data => {
        var keyswitchs_stl_url = data;
        SceneLoader.LoadAssetContainer(root, keyswitchs_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(0.25, 0.25, 0.25);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }
    
    if(show3DElems.keyswitchs) {
      getAxisStlFile(projectId).then(data => {
        var axis_stl_url = data;
        SceneLoader.LoadAssetContainer(root, axis_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(1.0, 0.0, 0.0);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }
    
    if(show3DElems.enclosure) {
      getEnclosureStlFile(projectId).then(data => {
        var enclosure_stl_url = data;
        SceneLoader.LoadAssetContainer(root, enclosure_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(0.8, 0.8, 0.0);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }
    
    if(show3DElems.module) {
      getModuleStlFile(projectId).then(data => {
        var module_stl_url = data;
        SceneLoader.LoadAssetContainer(root, module_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(0.0, 0.5, 0.0);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }

    if(show3DElems.module) {
      getPinheaderPinStlFile(projectId).then(data => {
        var pinheader_pin_stl_url = data;
        SceneLoader.LoadAssetContainer(root, pinheader_pin_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(1.0, 1.0, 0.5);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }

    if(show3DElems.module) {
      getPinheaderPlasticStlFile(projectId).then(data => {
        var pinheader_plastic_stl_url = data;
        SceneLoader.LoadAssetContainer(root, pinheader_plastic_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(0.1, 0.1, 0.1);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }

    if(show3DElems.module) {
      getUsbStlFile(projectId).then(data => {
        var usb_stl_url = data;
        SceneLoader.LoadAssetContainer(root, usb_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(0.7, 0.7, 0.7);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }

    if(show3DElems.pcb) {
      getPcbStlFile(projectId).then(data => {
        var pcb_stl_url = data;
        SceneLoader.LoadAssetContainer(root, pcb_stl_url, scene, function (container) {
          container.addAllToScene();
          var meshe = container.meshes[0];
          var material = new StandardMaterial("material", scene);
          material.diffuseColor = new Color3(0.8, 0.6, 0.3);
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          meshe.material = material;
        });
      });
    }
  }
  
  // --------------------------------
  
  return (
    <Box>
      { stlGenerated ?
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            <ThreeDScene antialias onSceneReady={onSceneReady} width={720} height={360} />
            <ToggleButtonGroup size="small" 
              sx={{ mt: 2 }}>
              <ToggleButton value="keycaps" onClick={showHandle} selected={ show3DElems.keycaps ? true : false }>
                {t('Keycaps')}
              </ToggleButton>
              <ToggleButton value="keyswitchs" onClick={showHandle} selected={ show3DElems.keyswitchs ? true : false }>
                {t('Keyswitchs')}
              </ToggleButton>
              <ToggleButton value="pcb" onClick={showHandle} selected={ show3DElems.pcb ? true : false }>
                {t('Circuit board')}
              </ToggleButton>
              <ToggleButton value="module" onClick={showHandle} selected={ show3DElems.module ? true : false }>
                {t('Module')}
              </ToggleButton>
              <ToggleButton value="enclosure" onClick={showHandle} selected={ show3DElems.enclosure ? true : false }>
                {t('Enclosure')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        :
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' }}>
            { !stlFailed ?
              <CircularProgressWithLabel label={getStatusWithProgress()} />
            :
              ""
            }
            { stlStatus && !stlFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>status: {stlStatus}</Typography>
            :
              ""
            } 
            <Typography>3D</Typography>
            { stlFailed ?
              <Typography variant="caption" mt={0.4} mb={0.1} sx={{ color: 'text.secondary' }}>genetation failed</Typography>
            :
              ""
            }
          </Box>
      }
    </Box>
  )
}

